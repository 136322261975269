<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="2" class="pr-12">
          <h1>Submissions</h1>
        </v-col>
        <v-col cols="6" class="pl-12">
          <v-text-field
            label="Search Submissions"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            v-if="formNames.length"
            label="Form"
            outlined
            dense
            :items="formNames"
            item-text="form_name"
            item-value="form_name"
            v-model="searchFormName"
            background-color="white"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="warning"
              class="mr-2"
              :to="{ name: 'module-contactforms-recipients' }"
              ><v-icon left>mdi-email-send</v-icon> Recipients</v-btn
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-contactforms-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Submissions</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="submissions"
        no-data-text="There are currently no submissions"
      >
        <template v-slot:item.page_url="{ item }"
          >/{{ item.page_url }}</template
        >
        <template v-slot:item.created_at="{ item }">
          {{ item.formatted_dates.created_at }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            depressed
            color="green lighten-4 green--text"
            class="mr-2"
            @click="openSubmissionDialog(item)"
          >
            <v-icon x-small left>mdi-eye</v-icon> View
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteDialog(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="submissionDialog.open"
      scrollable
      max-width="1000"
      @click:outside="resetSubmissionDialog()"
    >
      <v-card>
        <v-card-title class="headline">View Submission</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-simple-table>
            <tbody>
              <tr>
                <th>Full Name</th>
                <td>{{ submissionDialog.submission.full_name }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ submissionDialog.submission.email }}</td>
              </tr>
              <tr
                v-for="(input, name) in submissionDialog.submission.content"
                :key="name"
              >
                <th>{{ name }}</th>
                <td
                  style="padding-top: 8px; padding-bottom: 8px"
                  v-html="input"
                ></td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table class="mt-5">
            <tbody>
              <tr>
                <th>Submitted At</th>
                <td>
                  {{ submissionDialog.submission.formatted_dates.created_at }}
                </td>
              </tr>
              <tr>
                <th>Form Name</th>
                <td>{{ submissionDialog.submission.form_name }}</td>
              </tr>
              <tr>
                <th>IP Address</th>
                <td>{{ submissionDialog.submission.ip_address }}</td>
              </tr>
              <tr>
                <th>Page URL</th>
                <td>/{{ submissionDialog.submission.page_url }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetSubmissionDialog()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog.open" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Submission</v-card-title>
        <v-card-text
          >Are you sure you wish to delete the submission from
          {{ deleteDialog.submission.full_name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteDialog"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDeleteDialog"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      searchFormName: "",
      breadcrumbs: [
        {
          text: "Contact Forms",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Form Name", value: "form_name" },
        { text: "Page URL", value: "page_url" },
        { text: "Submitted At", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      submissionDialog: {
        open: false,
        submission: {
          full_name: null,
          email: null,
          content: [],
          form_name: null,
          page_url: null,
          formatted_dates: {
            created_at: null,
          },
        },
      },
      deleteDialog: {
        open: false,
        submission: {},
        loading: false,
      },
    };
  },

  computed: {
    submissions() {
      let submissions = this.$store.state.contactForms["submissions"];

      if (this.searchTerm !== "" || this.searchFormName !== "") {
        submissions = submissions.filter((s) => {
          const name = s.full_name.toLowerCase();
          const email = s.email.toLowerCase();
          const formName = s.form_name.toLowerCase();
          const pageUrl = s.page_url.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();
          const searchFormName = this.searchFormName;
          const type = s.form_name;

          return (
            (name.includes(searchTerm) ||
              email.includes(searchTerm) ||
              formName.includes(searchTerm) ||
              pageUrl.includes(searchTerm)) &&
            type.includes(searchFormName)
          );
        });
      }

      return submissions;
    },

    formNames() {
      let formNames = this.$store.state.contactForms["formNames"];
      return formNames;
    },
  },

  methods: {
    openSubmissionDialog(submission) {
      this.submissionDialog.submission = submission;
      this.submissionDialog.open = true;
    },

    resetSubmissionDialog() {
      this.submissionDialog.open = false;
      this.submissionDialog.submission = {
        full_name: null,
        email: null,
        content: [],
        form_name: null,
        page_url: null,
        formatted_dates: {
          created_at: null,
        },
      };
    },

    getSubmissionInput(input) {
      if (this.submissionDialog.submission.hasOwnProperty(input)) {
        return this.submissionDialog.submission[input];
      }

      return null;
    },

    openDeleteDialog(submission) {
      this.deleteDialog.submission = submission;
      this.deleteDialog.open = true;
    },

    resetDeleteDialog() {
      this.deleteDialog.open = false;
      this.deleteDialog.submission = {};
      this.deleteDialog.loading = false;
    },

    saveDeleteDialog() {
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("contactForms/deleteSubmission", {
          appId: this.$route.params.id,
          submissionId: this.deleteDialog.submission.id,
        })
        .then(() => {
          this.resetDeleteDialog();
        })
        .catch(() => {
          this.deleteDialog.loading = false;
        });
    },
  },
};
</script>
